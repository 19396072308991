<template>
  <div class="email-sent">
    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <img :src="icon" class="page-icon" alt="Email icon">

    <!-- Title and subtitle -->
    <h1 class="auth-title">{{ title }}</h1>
    <div class="auth-subtitle page-subtitle">
      <slot name="text"/>
    </div>
    <button type="button" class="pink-button" @click="onButtonClick()">{{ buttonText }}</button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'EmailSent',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()

    function onButtonClick() {
      router.push({ name: props.routeName })
    }

    return {
      onButtonClick,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.email-sent {
  margin: 0 auto;
  max-width: rem(481);
}

.page-icon {
  height: auto;
  margin-bottom: rem(48);
  width: rem(140);
}

.page-subtitle {
  margin-bottom: rem(51);
  margin-top: rem(17);
}
</style>
