<template>
  <EmailSent :icon="require('../../../assets/icons/icn_email.svg')"
             :title="$t('EMAIL_SENT.TITLE')"
             :button-text="$t('BACK_TO_LOGIN')"
             :route-name="ROUTE_NAMES_AUTH.LOGIN">
    <template #text>
      <i18n-t keypath="EMAIL_SENT.SUBTITLE" tag="p">
        <template #email>
          <strong> {{ email }} </strong>
        </template>
      </i18n-t>

    </template>
  </EmailSent>
</template>

<script>
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import EmailSent from '@/components/elements/auth/EmailSent'
import { useRoute } from 'vue-router'

export default {
  name: 'RegisterEmailSent',
  components: {
    EmailSent
  },
  setup() {
    const route = useRoute()

    return {
      ROUTE_NAMES_AUTH,
      email: route.query.email
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
</style>
